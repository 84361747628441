<template>
    <div class="main-block" v-loading="loading">
        <draggable-list
            :list-width="listWidth"
            :default-order="orderTasks"
            :tasks="tasks"
            :lists="lists"
            :groups="groups"
            :list-header-template="listHeaderTemplate"
            :interactive="changeInteractive.type"
            :list-header-style="listHeaderStyle"
            :list-footer-template="listFooterTemplate"
            :list-footer-style="listFooterStyle"
            :task-template="taskTemplate"
            :task-style="taskStyle"
            :footer-is-visible="footerIsVisible"
            :additional-fields="source.listData.additionalFields"
            @change="onChange"
            @custom-change="onChangeCustomSorting"
            :condition-moving="conditionMoving"
        ></draggable-list>
      <el-dropdown trigger="click" class="options" @command="runOptionCommand" size="small">
      <span class="el-dropdown-link">
        <i class="el-icon-more trigger"></i>
      </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-refresh-right" command="reload">{{ $locale.main.button.update }}</el-dropdown-item>
          <el-dropdown-item v-if="source.listData && source.listData.isAdditionalSource" icon="el-icon-refresh-right" command="reloadWithParam">
            {{ this.allLists ? $locale.interface_editor.component_editor.editor_tasks_source.button_not_all : $locale.interface_editor.component_editor.editor_tasks_source.button_all }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <slot></slot>
    </div>
</template>

<script>
import mixin from '../mixins'
import DraggableList from './DraggableList'
import FilterBuilder, { EComponentTypes, EFilterVariant } from '../utils'
import Data from '@/components/InterfaceEditor/components/basic/Tasks/Data'

export default {
  name: 'a-tasks',
  mixins: [mixin],
  components: {
    DraggableList,
    Data
  },
  inject: {
    isEditor: {
      default: () => false
    },
    openRegistryCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  provide () {
    return { loadData: this.loadData }
  },
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    listWidth: {
      type: Number,
      description: 'width_column',
      default: 300
    },
    source: {
      type: Object,
      default () {
        return {
          sourceData: {
            type: null,
            entityId: null,
            field: null,
            limit: 500,
            sorting: null,
            customSorting: false,
            sortingDirection: 'ASC',
            fieldIsLink: true,
            filters: [],
            allLists: false
          },
          listData: {
            isAdditionalSource: false,
            type: null,
            entityId: null,
            field: null,
            additionalFields: [],
            sortingByAdditionalField: false,
            sorting: null,
            sortingDirection: 'ASC',
            filters: [],
            hasColumnEmptyTasks: false
          },
          available_drag: null
        }
      },
      editor: 'TasksSource'
    },
    defaultOrder: {
      type: String,
      description: 'sort',
      default: null
    },
    groupFields: {
      type: Array,
      default () {
        return []
      },
      editor: 'TasksGroups'
    },
    listHeaderStyle: {
      type: String,
      description: 'header_style',
      default: 'background: #E9EBED;border-radius: 8px;'
    },
    listFooterStyle: {
      type: String,
      description: 'footer_style',
      default: 'background: #E9EBED;border-radius: 8px;'
    },
    footerIsVisible: {
      type: Boolean,
      description: 'footer_show'
    },
    taskStyle: {
      type: String,
      description: 'style_block',
      default: 'background: #FFFFFF;border-radius: 8px;min-height: 200px;cursor: pointer;margin: 6px;'
    },
    changeInteractive: {
      type: Object,
      default: () => {
        return {
          type: null,
          properties: {}
        }
      },
      editor: 'TasksChangeInteractive'
    },
    listHeaderTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 100,
        height: 48,
        style: 'listHeaderStyle'
      },
      description: 'header_template',
      editor: 'HtmlDesign'
    },
    listFooterTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 100,
        height: 48,
        style: 'listFooterStyle'
      },
      description: 'footer_template',
      editor: 'HtmlDesign'
    },
    taskTemplate: {
      type: Array,
      default: () => {
        return []
      },
      options: {
        width: 100,
        height: 200,
        style: 'taskStyle'
      },
      description: 'block_template',
      editor: 'HtmlDesign'
    }
  },
  data () {
    return {
      tasks: [],
      lists: [],
      groups: [],
      loading: false,
      orderTasks: {},
      allLists: false
    }
  },
  mounted () {
    this.allLists = this.source.listData && this.source.listData.allLists ? this.source.listData.allLists : false
    setTimeout(() => {
      if (!this.loading) {
        this.loadData()
      }
    }, 500)
  },
  watch: {
    'source.listData.allLists': {
      immediate: false,
      handler () {
        this.allLists = this.source.listData.allLists
      }
    },
    sourceDataFilters: {
      immediate: false,
      handler () {
        this.loadData()
      }
    },
    listDataFilters: {
      immediate: false,
      handler () {
        this.loadData()
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  computed: {
    conditionMoving () {
      let simpleFields = []
      let additionalFields = []

      let simpleFieldsSource = this.source.listData.conditionMoving ?? []
      additionalFields = this.source.listData.additionalFields.filter(item => item.hasCondition)

      if (simpleFieldsSource.length) {
        let sourceData = this.getSource(
          this.source.sourceData.type,
          this.source.sourceData.entityId
        )
        let listData = this.getSource(
          this.source.listData.type,
          this.source.listData.entityId
        )
        simpleFieldsSource.forEach(function (item) {
          if (item.listField && item.sourceField) {
            let listField = listData.source.getPreparedField(item.listField)
            let sourceField = sourceData.source.getPreparedField(item.sourceField)
            let textError = item.textError
            simpleFields.push({ listField, sourceField, textError })
          }
        })
      }
      return {
        simpleFields,
        additionalFields
      }
    },
    sourceDataFilters () {
      return this.getFilters(this.source.sourceData.filters, this.source.sourceData.type)
    },
    listDataFilters () {
      return this.getFilters(this.source.listData.filters, this.source.listData.type)
    }
  },
  methods: {
    getFilters (filters, type) {
      const builder = new FilterBuilder(
        filters,
        this.getModel(),
        this.$store,
        EComponentTypes.tasks
      )
      return builder.build(
        type === 'registry' ? EFilterVariant.registry_service : EFilterVariant.api_ql
      )
    },
    runOptionCommand (command) {
      switch (command) {
        case 'reload':
          break
        case 'reloadWithParam':
          this.allLists = !this.allLists
          break
        default:
          break
      }
      this.loadData()
    },
    onChangeCustomSorting (list) {
      let sourceData = this.source.sourceData
      if (sourceData.type === 'registry') {
        let sortingFields = `attr_${sourceData.sorting}_`
        list = list.map((item) => {
          let newItem = {
            id: item.id,
            object_id: sourceData.entityId
          }
          newItem[sortingFields] = item[sortingFields]
          return newItem
        })
        this.$http.put(`${this.$config.api}/registryservice/registry/${sourceData.entityId}/records/list`, list)
      }
    },
    onChange (element) {
      let sourceData = this.source.sourceData
      if (sourceData.type === 'registry') {
        let data = {
          object_id: element.object_id,
          id: element.id
        }
        element[`attr_${sourceData.field}_`] = element.link
        data[`attr_${sourceData.field}_`] = element.link
        this.$http.put(`${this.$config.api}/registryservice/registry/${sourceData.entityId}/records/${element.id}`, data)
      }
    },
    getSource (type, entityId) {
      return new Data(this.$http, this.$config, entityId, type)
    },
    async loadData () {
      let params = this.source
      this.loading = true
      let sourceData = params.sourceData
      let listData = params.listData
      let links = []
      let tasks = []
      let source = {}
      if (sourceData.type && sourceData.entityId && sourceData.field) {
        source = this.getSource(
          sourceData.type,
          sourceData.entityId
        )
        tasks = await source.getItems(this.sourceDataFilters, sourceData.limit ?? 500)

        this.orderTasks = {
          'field': sourceData.sorting ? source.source.getPreparedField(sourceData.sorting) : null,
          'direction': sourceData.sortingDirection,
          'customSorting': sourceData.customSorting
        }
        tasks = source.setLinkFieldItems(tasks, sourceData.field)

        if (listData.isAdditionalSource) {
          tasks.forEach(function (item, key) {
            let link = null
            try {
              link = JSON.parse(item.link)[0].id
            } catch {
              link = item.link
            }
            if (['[]', '', null, undefined].includes(link)) {
              link = null
              tasks[key].link = link
            }
            if (!links.includes(link)) {
              links.push(link)
            }
          }
          )
        }
      }
      let lists = []
      if (sourceData.field) {
        if (listData.isAdditionalSource && listData.type && listData.entityId) {
          source = this.getSource(
            listData.type,
            listData.entityId
          )
          lists = await source.getItems(this.listDataFilters, 500)

          let field = 'id'
          if (listData.field) {
            field = source.source.getPreparedField(listData.field)
          }
          lists = source.setLinkFieldItems(lists, field)

          if (!this.allLists) {
            lists = lists.filter(function (item) {
              if (links.includes(item.link)) return true
            })
          } else if (listData.hasColumnEmptyTasks) {
            lists.push({ link: null, id: null })
          }
        } else if (sourceData.field) {
          lists = source.getByTaskAndAttr(tasks, sourceData.field)
        }
        lists = Data.addFieldsItems(lists, tasks, listData.additionalFields)
        let field = listData.sorting ? listData.sorting : sourceData.field
        field = source.source.getPreparedField(field)
        lists = Data.sortedItemsByField(lists, field, listData.sortingDirection)
      }

      this.tasks = tasks
      this.lists = lists
      this.groups = this.getGroupedTasks(this.tasks, this.groupFields)
      this.loading = false
    },
    getGroupedTasks (tasks, groups) {
      if ((groups || []).length === 0) {
        return []
      }
      let result = this.getGroup(tasks, groups[0].name)
      if (groups.length > 1) {
        result = result.map((item) => {
          return {
            name: item.name,
            children: this.getGroup(item.tasks, groups[1].name)
          }
        })
      }

      return result
    },
    getGroup (tasks, group) {
      let groups = this.groupBy(tasks, group)
      let result = []
      for (let group in groups) {
        let name = ''
        let id = null
        try {
          name = JSON.parse(group)[0].name
          id = JSON.parse(group)[0].id
        } catch {
          name = group
          id = group
        }
        result.push({
          name: name,
          tasks: groups[group]
        })
      }
      return result
    },
    groupBy (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    }
  }
}
</script>

<style scoped>
.main-block {
    height: 100%;
    display: contents;
}
.options {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 5px;
  z-index: 99999;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) -1px 1px 2px;
  border-bottom-left-radius: 5px;
}
.options .trigger {
  cursor: pointer;
  color: #409EFF;
}
</style>
